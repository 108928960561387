import { Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { RescueOrgInUser } from '../rescue-org/rescue-org.store';

export type UserRoles = 'admin' | 'rescuer' | null;

export interface GlobalUserRoles {
  superAdmin?: boolean;
}

export interface FirebaseUserRescueOrg {
  [key: string]: UserOrgDetails
}

export interface UserOrgDetails {
  name: string;
  favourite: boolean;
  role: UserRoles;
}

export interface UserState {
  id: string;
  fullName: string;
  phone: string;
  globalRoles: GlobalUserRoles;
  email: string;
  connectedOrgs: RescueOrgInUser[];
  selectedOrgId: string;
}

export function createInitialState(): UserState {
  return {
    id: '',
    fullName: '',
    phone: '',
    globalRoles: {},
    email: '',
    connectedOrgs: [],
    selectedOrgId: '',
  }
}
@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'user' })
export class UserStore extends Store<UserState> {
  constructor() {
    super(createInitialState());
  }
}
