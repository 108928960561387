import { Query } from '@datorama/akita';
import { UserRoles, UserState, UserStore } from './user.store';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class UserQuery extends Query<UserState> {
  selectFullName$ = this.select('fullName');
  selectEmail$ = this.select('email');
  selectMonogram$ = this.select(state => this.getMonogram(state.fullName));
  selectSelectedRescueOrgId$ = this.select('selectedOrgId');
  selectConnectedOrgs$ = this.select('connectedOrgs');
  selectRoles$ = this.select((state) => Object.keys(state.globalRoles).filter((key ) => state.globalRoles[key as keyof UserRoles]));

  constructor(protected override store: UserStore) {
    super(store);
  }

  private getMonogram(fullName: string) {
    return fullName.split(' ').map((namePart) =>namePart.charAt(0).toUpperCase()).join('');
  }

  get fullName() {
    return this.getValue().fullName;
  }

  get selectedRescueOrgId() {
    return this.getValue().selectedOrgId;
  }
  get connectedOrgs() {
    return this.getValue().connectedOrgs;
  }

  get favouriteRescueOrg() {
    return this.connectedOrgs.find((org) => org.favourite);
  }

  get uid() {
    return this.getValue().id;
  }

  get isLoggedIn() {
    return !!this.getValue().fullName;
  }

  get firebaseUser() {
    return {
      fullName: this.getValue().fullName,
      connectedOrgs: this.getValue().connectedOrgs,
      globalRoles: this.getValue().globalRoles,
      email: this.getValue().email
    }
  }
}
